import React from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Layout from '../components/layout'
import PageTitle from '../components/page-title'
import Home from '../images/services/home.png'
import Auto from '../images/services/auto.png'
import Travel from '../images/services/travel.png'
import Business from '../images/services/business.png'
import SEO from '../components/seo'
import { myContext } from '../../provider'

const Service = styled.div`
  background-color: #f8f8f8;
  height: 608px;
  margin-bottom: 3.125rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;
`

const ServiceImage = styled.div`
  flex: 1;
  height: 50%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:after {
    display: block;
    content: ' ';
    position: absolute;
    width: 90px;
    height: 90px;
    border-radius: 45px;
    z-index: 100;
    background-color: #00a6a6;
    background-image: url(${(props) => {
      return props.image || Home
    }});
    background-size: 70% auto;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    right: 45px;
    transform: translateY(-50%);
  }
`

const ServiceContent = styled.div`
  display: flex;
  flex: 1;
  color: #4a4a4a;
  padding: 1rem;
  flex-direction: column;
  p {
    flex: 1;
  }
`

const Services = ({ data }) => {
  const nodes = data.allContentfulHomePage.nodes

  return (
    <Layout>
      <SEO title="Services" />
      <myContext.Consumer>
        {({ language }) => {
          const homePageText = nodes.find((node) => node.language === language)
          return (
            <>
              <PageTitle title="Services" />
              <Container>
                <Row>
                  <Col lg={6}>
                    <Service>
                      <ServiceImage image={Home}>
                        <img src={`https://picsum.photos/900/900`} />
                      </ServiceImage>
                      <ServiceContent>
                        <h4>{homePageText.homeServiceTitle}</h4>
                        <p>{homePageText.homeService.homeService}</p>
                        <div>
                          <Button>{homePageText.readMoreButton}</Button>
                        </div>
                      </ServiceContent>
                    </Service>
                  </Col>
                  <Col lg={6}>
                    <Service>
                      <ServiceImage image={Auto}>
                        <img src={`https://picsum.photos/900/900`} />
                      </ServiceImage>
                      <ServiceContent>
                        <h4>{homePageText.autoServiceTitle}</h4>
                        <p>{homePageText.autoService.autoService}</p>
                        <div>
                          <Button>{homePageText.readMoreButton}</Button>
                        </div>
                      </ServiceContent>
                    </Service>
                  </Col>
                  <Col lg={6}>
                    <Service>
                      <ServiceImage image={Travel}>
                        <img src={`https://picsum.photos/900/900`} />
                      </ServiceImage>
                      <ServiceContent>
                        <h4>{homePageText.travelServiceTitle}</h4>
                        <p>{homePageText.travelService.travelService}</p>
                        <div>
                          <Button>{homePageText.readMoreButton}</Button>
                        </div>
                      </ServiceContent>
                    </Service>
                  </Col>
                  <Col lg={6}>
                    <Service>
                      <ServiceImage image={Business}>
                        <img src={`https://picsum.photos/900/900`} />
                      </ServiceImage>
                      <ServiceContent>
                        <h4>{homePageText.businessServiceTitle}</h4>
                        <p>{homePageText.businessService.businessService}</p>
                        <div>
                          <Button>{homePageText.readMoreButton}</Button>
                        </div>
                      </ServiceContent>
                    </Service>
                  </Col>
                </Row>
              </Container>
            </>
          )
        }}
      </myContext.Consumer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ServicePageQuery {
    allContentfulHomePage {
      nodes {
        aboutVina {
          aboutVina
        }
        autoService {
          autoService
        }
        autoServiceTitle
        businessService {
          businessService
        }
        businessServiceTitle
        heroBody {
          heroBody
        }
        heroSubHeader
        homeService {
          homeService
        }
        homeServiceTitle
        language
        quoteButton
        readMoreButton
        travelServiceTitle
        travelService {
          travelService
        }
      }
    }
  }
`

export default Services
